import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const DropdownWrapper = styled("section", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  minWidth: "120px",
  "& .MuiOutlinedInput-root": {
    color: theme.palette.primary.main,
    padding: "6px !important",
    "& fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiAutocomplete-input": {},
  },
  "& .MuiAutocomplete-popupIndicator": {
    color: theme.palette.primary.main,
    // height: "15px",
    width: "15px",
  },
}));

export { DropdownWrapper };
