import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import "swiper/css/free-mode";
import 'swiper/css/navigation';
import { FreeMode, Scrollbar, Navigation } from "swiper";
import { useRouter } from "next/router";
import { SwiperStyled } from "./style";
const StoreySwiper = ({ children, onReachEnd }) => {
  const router = useRouter();

  return (
    <SwiperStyled>
      <Swiper
        // slidesPerView={"auto"}
        spaceBetween={24}
        centeredSlides={false}
        freeMode={true}
        slidesPerView={"auto"}
        modules={[FreeMode, Scrollbar]}
        className="mySwiper"
        dir={"ltr"}
        scrollbar={{
          hide: true,
        }}
        onReachEnd={onReachEnd}
        // breakpoints={{
        //   400: {
        //     slidesPerView: 3,
        //   },
        //   600: {
        //     slidesPerView: 4,
        //   },
        //   800: {
        //     slidesPerView: 5,
        //   },
        //   900: {
        //     slidesPerView: 6,
        //   },
        //   1000: {
        //     slidesPerView: 8,
        //   },
        //   1200: {
        //     slidesPerView: 12,
        //   },
        //   1536: {
        //     slidesPerView: 13,
        //   },
        // }}
      >
        {children}
      </Swiper>
    </SwiperStyled>
  );
};

export default StoreySwiper;
