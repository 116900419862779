/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { WithSeeMore } from "react-insta-stories";
import WithCustomHeader from "./withCustomHeader";
import Image from "next/image";
import FavoriteSharpIcon from "@mui/icons-material/FavoriteSharp";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IconButton from "@mui/material/IconButton";
import useMakeLikeAndUnLike from "../../components/Stories/hooks/useMakeLikeAndUnLike";
import { useAuth } from "../../context/auth";
import { useRouter } from "next/router";
import { InlineShareButtons } from "sharethis-reactjs";
import { rgbDataURL, WEB_PATH } from "../utils";
import shareIcon from "../../../public/images/share-icon.png";
import { useTranslation } from "next-i18next";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import {
  CopyIcon,
  CopyIconWrapper,
} from "../../components/PropertyCard/components/Actions/style";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import {
  AddLinkWrapper,
  FavButtonWrapper,
  ProfileAvatarWrapper,
} from "./style";
import { Typography } from "@mui/material";
import Link from "next/link";
import { AiOutlineEye } from "react-icons/ai";
import { event } from "../gtm";

export const renderer = ({ story, action, isPaused, config }) => {
  const { t } = useTranslation("common");
  const [loaded, setLoaded] = useState(false);
  const router = useRouter();
  const { user } = useAuth();
  const [value, copy] = useCopyToClipboard();
  const [anchorEl, setAnchorEl] = useState(null);

  const { width, height, loader, storyStyles } = config;
  let computedStyles = {
    ...styles.storyContent,
    ...(storyStyles || {}),
  };

  const imageLoaded = () => {
    setLoaded(true);
    action("play");
  };

  const { mutate: makeReaction, isLoading: likeLoading } =
    useMakeLikeAndUnLike();

  const handleFavButtonSelect = (id, liked) => {
    if (user) {
      makeReaction(
        { id, liked },
        {
          onSuccess: () => {
            action("play", true);
          },
          onError: () => {
            action("play", true);
          },
        }
      );
    } else {
      router?.push({
        pathname: "/login",
        query: { redirect: `/` },
      });
    }
  };
  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = anchorEl ? true : false;
  const id = open ? "simple-popover" : "";
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <WithCustomHeader story={story} globalHeader={config.header}>
      <WithSeeMore story={story} action={action}>
      <Image
          width={100}
          height={100}
          style={computedStyles}
          src={story.url}
          onLoad={imageLoaded}
          alt="story image"
        />

        {!loaded && (
          <div
            style={{
              width: width,
              height: height,
              position: "absolute",
              left: 0,
              top: 0,
              background: "rgba(0, 0, 0, 0.9)",
              zIndex: 9,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#ccc",
            }}
          >
            {loader || (
              <Image
                width={50}
                height={50}
                src="/images/puff.svg"
                alt="loading..."
              />
            )}
          </div>
        )}
      </WithSeeMore>
      <FavButtonWrapper>
        <div style={{width: 50, display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          {story.viewCount > 0 && (
            <div style={{display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: 10}}>
              <IconButton
                aria-label="vertical contained button group"
                key="share"
                //onClick={handleShareClick}
              >
                <AiOutlineEye color="#fff" size={28}/>
              </IconButton>
              <p style={{fontSize: 10, color: "#fff"}}>{story.viewCount}</p>
            </div>
          )}
          <IconButton
            key="fav_button"
            onClick={() => {
              event({
                action: "story_like",
                params: {
                  type: "image",
                  story_id: story?.id
                }
              });
              action("pause", true);
              handleFavButtonSelect(story?.id, story?.isLiked);
              story.isLiked = !story.isLiked;
            }}
          >
            <>
              {story?.isLiked == true ? (
                <FavoriteSharpIcon color="red" style={{ fontSize: "28px" }} />
              ) : (
                <FavoriteBorderIcon color="red" style={{ fontSize: "28px" }} />
              )}
            </>
          </IconButton>
          {/* <IconButton
            aria-label="vertical contained button group"
            key="share"
            onClick={handleShareClick}
          >
            <Image
              src={shareIcon}
              alt={t("alt-icon")}
              width={24}
              height={24}
              blurDataURL={rgbDataURL(255, 255, 255)}
              style={{ objectFit: "contain" }}
            />
          </IconButton> */}
        </div>
      </FavButtonWrapper>
      {story?.linkType != "None" && (
        <Link
          href={
            story?.linkType == "Project"
              ? `project/${story?.entityId}`
              : story?.linkType == "Listing"
              ? `property/${story?.entityId}`
              : `project-modal/${story?.entityId}`
          }
        >
          <div onClick={() => {
            event({
              action: "see_add_clicked",
              params: {
                owner_id: story?.entityId
              }
            });
          }}>
            <AddLinkWrapper>
              {story?.entityImageThumbUrl && (
                <ProfileAvatarWrapper>
                  <img src={story?.entityImageThumbUrl} alt="Profile picture" />
                </ProfileAvatarWrapper>
              )}
              <Typography variant="body1" size="small" sx={{ color: "#fff" }}>
                {t("see_this_add")}
              </Typography>
            </AddLinkWrapper>
          </div>
        </Link>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box sx={{ padding: "10px", display: "flex" }}>
          <InlineShareButtons
            config={{
              alignment: "center", // alignment of buttons (left, center, right)
              color: "social", // set the color of buttons (social, white)
              enabled: true, // show/hide buttons (true, false)
              font_size: 16, // font size for the buttons
              labels: null, // button labels (cta, counts, null)
              language: router?.locale === "en" ? "en" : "ar", // which language to use (see LANGUAGES)
              networks: ["whatsapp", "facebook", "twitter", "linkedin", "copy"],
              padding: 12, // padding within buttons (INTEGER)
              radius: 4, // the corner radius on each button (INTEGER)
              show_total: false,
              size: 40, // the size of each button (INTEGER)

              // OPTIONAL PARAMETERS
              //data-url:`${WEB_PATH}property/${data?.data?.id}`,
              min_count: 10,
              url: WEB_PATH,

              username: story?.header?.heading,
            }}
          />
          <CopyIconWrapper
            onClick={() => {
              copy(`${WEB_PATH}`);
            }}
          >
            {value ? <CopyIcon copied /> : <CopyIcon />}
          </CopyIconWrapper>
          {/* <CopyIconWrapper
            onClick={() => {
              copy(
                data?.data?.documentType == 0
                  ? `${WEB_PATH}/property/${data?.data?.id}`
                  : `${WEB_PATH}/project/${data?.data?.id}`
              );
            }}
          >
            {value ? <CopyIcon copied /> : <CopyIcon />}

           
          </CopyIconWrapper> */}
        </Box>
      </Popover>
    </WithCustomHeader>
  );
};

const styles = {
  story: {
    display: "flex",
    position: "relative",
    overflow: "hidden",
  },
  storyContent: {
    width: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
    height: "auto",
  },
};

export const tester = (story) => {
  return {
    // condition: !story.content && (!story.type || story.type === "image"),
    condition: !story.type || story.type === "image",
    priority: 2,
  };
};

export default {
  renderer,
  tester,
};
