import { useQuery } from "react-query";
import { useAxios } from "../../../hooks/axios";

//fetch all stories
const useGetStories = (enabled = false) => {
  const axios = useAxios();
  return useQuery(
    ["stories"],
    async () => {
      const { data } = await axios.get("stories/v1");
      return data?.data;
    },
    {
      enabled: enabled,
    }
  );
};
export default useGetStories;
