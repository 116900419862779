import { useQuery } from "react-query";
import { useAxios } from "./axios";

//fetch all slider
const useGetApplicationSlider = () => {
  const axios = useAxios();
  return useQuery(["slider"], async () => {
    const { data } = await axios.get("profiles/v1/app/slider");
    return data;
  });
};
export default useGetApplicationSlider;
