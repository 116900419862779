import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const SpotlightStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  borderRadius: "15px",
  overflow: "hidden",
  "& .swiper-pagination-bullet": {
    backgroundColor: "#fff",
    opacity: 1,
  },
}));

const ImageWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  minHeight: "300px",
  width: "100%",
  backgroundColor: "#fff",
  // cursor: "pointer",
  [theme.breakpoints.up("sm")]: {
    minHeight: "350px",
  },
  [theme.breakpoints.up("md")]: {
    minHeight: "400px",
  },
  [theme.breakpoints.up("lg")]: {
    minHeight: "490px",
  },
}));

const ImageWrapperMobile = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  minHeight: "200px",
  width: "100%",
}));

const JoinNowWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  backgroundColor: "#fff",
  borderRadius: "15px",
  padding: "40px 20px 20px 20px",
}));

const CloseButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  zIndex: 99,
  top: 0,
  right: 0,
}));

export { SpotlightStyled, ImageWrapper, JoinNowWrapper, CloseButtonWrapper, ImageWrapperMobile };
