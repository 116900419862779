import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { borderRadius } from "@mui/system";

const PropertyOwnerCardStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  textAlign: "center",
}));

const Contents = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  padding: `${theme.typography.pxToRem(50)} ${theme.typography.pxToRem(
    20
  )} ${theme.typography.pxToRem(50)} ${theme.typography.pxToRem(20)}`,
  backgroundColor: theme.palette.lightGray.main,
  borderRadius: "24px",
  marginTop: "-35px",
  marginBottom: "35px",
  "& p": {
    textDecoration: "underline",
    textDecorationColor: "#F0F0F0",
  },
}));

const Avatar = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  display: "inline-block",
}));

const ImageWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  height: "70px",
  width: "70px",
  borderRadius: "50%",
  position: "relative",
  overflow: "hidden",
  boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.1)",
}));

const Verified = styled("img", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  height: "20px",
  width: "20px",
  right: 0,
  bottom: 0,
}));

export { PropertyOwnerCardStyled, Avatar, ImageWrapper, Verified, Contents };
