import React from "react";
import CustomStoryHeader from "../../components/common/CustomStoryHeader/CustomStoryHeader";

const WithCustomHeader = ({ story, globalHeader, children }) => {
  return (
    <>
      {children}
      {story.header && (
        <div
          style={{
            position: "absolute",
            left: 12,
            right: 12,
            top: 20,
            zIndex: 1001,
            direction: "ltr"
          }}
        >
          {typeof story === "object" ? (
            globalHeader ? (
              globalHeader(story.header)
            ) : (
              <CustomStoryHeader
                advertisementNumber={story.header.advertisementNumber}
                sellerUsername={story.header.sellerUsername}
                sellerId={story.header.sellerId}
                heading={story.header.heading}
                subheading={story.header.subheading}
                profileImage={story.header.profileImage}
              />
            )
          ) : null}
        </div>
      )}
    </>
  );
};

export default WithCustomHeader;
