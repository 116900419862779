import { val } from "dom7";
import { useRouter } from "next/router";
import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../../hooks/axios";

//to make a story like or unLike
const useMakeLikeAndUnLike = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    async (values) => {
      let type = values?.liked == false ? "like" : "unlike";
      const { data } = await axios.post(
        `/reactions/v1/story/${values?.id}/${type}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        // queryClient.invalidateQueries(["stories"]);
        // queryClient.refetchQueries(["stories"]);
        // let stories = queryClient.getQueryData(["stories"]);
        // if (stories) {
        //   let currentStoryIndex = _.findIndex(stories, {
        //     stories: [{ id: data?.data?.storyId }],
        //   });
        //   let currentStoryItem = _.findIndex(
        //     stories[currentStoryIndex]?.stories,
        //     {
        //       id: data?.data?.storyId,
        //     }
        //   );
        //   stories[currentStoryIndex]["stories"][currentStoryItem].isLiked =
        //     !stories[currentStoryIndex]?.stories?.[currentStoryItem].isLiked;
        //   queryClient.setQueryData(["stories"], stories);
        // }
      },
      onError: () => {},
    }
  );
};
export default useMakeLikeAndUnLike;
