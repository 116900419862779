import { Avatar, Box, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";

const BoxWithAvatar = () => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Skeleton variant="rounded" animation="wave" height={218} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ margin: 1 }}>
          <Skeleton variant="circular" width={250} height={5}>
            <Avatar />
          </Skeleton>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Box>
      <Skeleton height={20}/>
      <Skeleton width="60%" />
    </Grid>
  );
};

export default BoxWithAvatar;
