import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import hlsVideo from "../../utils/story/story-video-render";
import hlsImage from "../../utils/story/story-image-render";
import dynamic from "next/dynamic";
import CloseIcon from "@mui/icons-material/Close";
import { useAxios } from "../../hooks/axios";
import { isAndroid, isIOS } from "react-device-detect";
import { event } from "../../utils/gtm";

const Stories = dynamic((props) => import("react-insta-stories"), {
  ssr: false,
});

const StoryPop = ({ items, handleStoryClose, setAllStoryEnd ,setOpenStory ,openStory}) => {

  const axios = useAxios();
  const [open, setOpen] = useState(true);
  const [storyId, setStoryId] = useState([]);
  const handleClose = () => {
    setOpen(false);
    handleStoryClose();
    saveStoryViews();
  };

  useEffect(() => {
    if(storyId.length == 0) {
      let ids = [...storyId];
      setStoryId(ids.concat(items[0]["id"]));
    }
    setOpen(true);
  }, [items]);
 
  const saveStoryViews = async () => {
    if(storyId.length > 0) {
      const { data } = await axios.post(`/reactions/v1/story/viewed`, storyId);
      setTimeout(() => {
        setStoryId([]);
      }, 5000)
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isAndroid || isIOS}
      PaperProps={{
        style: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: "transparent",
          padding: "25px 0",
          boxShadow: "none",
          overflow: 'hidden',
          height: '100%',
          maxHeight: "150%",
          direction: "ltr"
        }
      }}
    >
      {/* <Box sx={{ textAlign: "center", mb: "24px", position: "relative" }}>
        <Image
          height={"35"}
          width={"100"}
          src={require("../../../public/images/footer-logo.png")}
          alt="Company logo"
        />
      </Box> */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: 'auto',
          maxWidth: "340px",
          position: "relative",
        }}
        className="story-pop-parent"
      >
        <CloseIcon
          style={{
            color: "white",
            cursor: "pointer",
            position: "absolute",
            right: 0,
            top: "-25px",
            zIndex: 10,
          }}
          onClick={handleClose}
        />
        <Stories
          key={items[0]["id"]}
          currentIndex={0}
          stories={items}
          defaultInterval={2000}
          width={340}
          renderers={[hlsVideo, hlsImage]}
          onAllStoriesEnd={(index, allStory) => {
            let ids = [...storyId];
            allStory.map(i => {
              setStoryId(ids.concat(i.id));
            });
            event({
              action: "story_completion",
              params: {}
            });
              setAllStoryEnd(allStory[index].id);
              setOpenStory(openStory + 1)
  
          }}
          className="story-pop-parent"
          loop
          autoplay
        />
      </Box>
    </Dialog>
    // </Backdrop>
  );
};

export default StoryPop;


