import React from "react";
import { ContainerStyled, FilteredUsersStyled } from "./style";
import PropertyList from "./components/PropertyList";

const PropertyWithFilter = ({ search }) => {
  return (
    <FilteredUsersStyled>
        <PropertyList search={search} />
    </FilteredUsersStyled>
  );
};

export default PropertyWithFilter;
