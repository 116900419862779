import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { Hidden } from "@mui/material";

const FilterBarStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "filterBarVisible",
})(({ theme, filterBarVisible }) => ({
  display: filterBarVisible ? "flex" : "none",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: "row",
  },
}));

const FilterBarWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  padding: "20px 15px",
  backgroundColor: "#fff",
  position: "sticky",
  top: "72px",
  zIndex: 999,
  margin: "0 -15px",
  [theme.breakpoints.up("md")]: {
    padding: "32px 15px",
  },
}));

const FilterBlockWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const LeftBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  order: 1,
  // marginBottom: "15px",
  [theme.breakpoints.up("sm")]: {
    order: 1,
    marginBottom: 0,
    // marginBottom: "20px",
  },
  [theme.breakpoints.up("md")]: {
    order: 1,
    marginBottom: 0,
  },
}));

const RightBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  order: 2,
  [theme.breakpoints.up("md")]: {
    order: 3,
  },
}));

const CenterBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  order: 3,
  margin: "8px auto 0 auto",
  display: "none",
  [theme.breakpoints.up("sm")]: {
    margin: "8px auto 0 auto",
    display: "none",
  },
  [theme.breakpoints.up("md")]: {
    margin: "0 auto",
    order: 2,
    display: "block",
  },
  [theme.breakpoints.up("lg")]: {
    margin: "0 auto",
  },
}));

const Divider = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "filterBarVisible",
})(({ theme, filterBarVisible }) => ({
  width: "100%",
  borderBottom: "1px solid #EBEBEB",
  margin: "15px 0 15px auto",
  display: filterBarVisible ? "block" : "none",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const SortBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const FilterButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: 0,
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&.MuiButtonBase-root.Mui-disabled": {
      backgroundColor: "#C9C9C9",
      color: "#7D7D7D",
    },
    "&.MuiButtonBase-root": {
      backgroundColor: "#F7F8FA",
      textTransform: "capitalize",
      color: "#909193",
      fontSize: "16px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "#00BEAD",
      color: "#fff",
      textTransform: "capitalize",
      transition: "opacity 0.2s ease",
      fontSize: "16px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#00BEAD",
      opacity: 0.7,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
      padding: "5px 10px",
      height: "30px",
      [theme.breakpoints.up("sm")]: {
        padding: "5px 23px",
        height: "40px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "5px 10px",
        height: "30px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "5px 23px",
        height: "40px",
      },
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
      borderRadius: "6px",
      padding: "5px 10px",
      height: "30px",
      [theme.breakpoints.up("sm")]: {
        padding: "5px 23px",
        height: "40px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "5px 10px",
        height: "30px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "5px 23px",
        height: "40px",
      },
    },
  },
}));

const FilterButtonWrapperAdvancedSearch = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: 0,
    border: 0,
    width:"150px",
    paddingLeft:"150px",
    paddingRight:"150px",
    alignItems: "center",
    marginLeft:"110%",
   overflow:'hidden',
  //  position:"relative",
  //  position: "absolute",
   // position: "static", 
   position: "float", 
    // top:50,
   zindex:10000,
    "&.Mui-disabled": {
      border: 0,
    },
    "&.MuiButtonBase-root.Mui-disabled": {
      backgroundColor: "#C9C9C9",
      color: "#7D7D7D",
    },
    "&.MuiButtonBase-root": {
      backgroundColor: "#F7F8FA",
      textTransform: "capitalize",
      color: "#909193",
      fontSize: "16px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "#00BEAD",
      color: "#fff",
      textTransform: "capitalize",
      transition: "opacity 0.2s ease",
      fontSize: "16px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#00BEAD",
      opacity: 0.7,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
      // padding: "5px 10px",
     
      height: "30px",
      [theme.breakpoints.up("sm")]: {
        // padding: "5px 23px",
        height: "40px",
      },
      [theme.breakpoints.up("md")]: {
        // padding: "5px 10px",
        height: "30px",
      },
      [theme.breakpoints.up("lg")]: {
        // padding: "5px 23px",
        height: "40px",
      },
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
      borderRadius: "6px",
      // padding: "5px 10px",
      height: "30px",
      [theme.breakpoints.up("sm")]: {
        // padding: "5px 23px",
        height: "40px",
      },
      [theme.breakpoints.up("md")]: {
        // padding: "5px 10px",
        height: "30px",
      },
      [theme.breakpoints.up("lg")]: {
        // padding: "5px 23px",
        height: "40px",
      },
    },
  },
}));

const FilterTabButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: 0,
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&.MuiButtonBase-root": {
      textTransform: "capitalize",
      color: "#000000",
      fontSize: "16px",
      backgroundColor: "transparent",
      minWidth: "75px",
      fontWeight: "bold",
      [theme.breakpoints.up("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
      textTransform: "capitalize",
      fontSize: "16px",
      minWidth: "75px",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      fontWeight: "bold",
      [theme.breakpoints.up("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
      },
    },
    "&:not(:first-of-type)": {
      borderRadius: 0,
      padding: "5px 10px",
      height: "30px",
      [theme.breakpoints.up("sm")]: {
        padding: "5px 23px",
        height: "40px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "5px 10px",
        height: "30px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "5px 23px",
        height: "40px",
      },
    },
    "&:first-of-type": {
      borderRadius: 0,
      padding: "5px 10px",
      height: "30px",
      [theme.breakpoints.up("sm")]: {
        padding: "5px 23px",
        height: "40px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "5px 10px",
        height: "30px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "5px 23px",
        height: "40px",
      },
    },
  },
}));

const DropdownWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  minWidth: "140px",
  [theme.breakpoints.up("sm")]: {
    minWidth: "160px",
  },
  "& .MuiAutocomplete-root": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "5px",
    margin: "0",
    "& fieldset": {
      padding: "0px !important",
      borderColor: "transparent",
      fontSize: "12px",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
    "& .MuiAutocomplete-input": {
      padding: "2px 5px !important",
    },
    "&.MuiFormControl-root p": {
      display: "none !important",
    },
    "& .MuiInputBase-root": {
      color: "#fff",
    },
    "& .MuiInputBase-root input::placeholder": {
      color: "#fff",
      opacity: 1,
    },
    "& .MuiInputBase-root input": {
      fontSize: "12px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
    "& .Mui-disabled": {
      backgroundColor: "#C9C9C9",
      cursor: "not-allowed",
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      color: "#fff",
    },
  },
}));
const DropdownWrapperSort = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  minWidth: "90px",
  [theme.breakpoints.up("sm")]: {
    minWidth: "120px",
  },

  "& .MuiAutocomplete-root": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "5px",
    margin: "0",
    "& fieldset": {
      padding: "0px !important",
      borderColor: "transparent",
      fontSize: "12px",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
    "& .MuiAutocomplete-input": {
      padding: "2px 5px !important",
    },
    "&.MuiFormControl-root p": {
      display: "none !important",
    },
    "& .MuiInputBase-root": {
      color: "#fff",
    },
    "& .MuiInputBase-root input::placeholder": {
      color: "#fff",
      opacity: 1,
    },
    "& .MuiInputBase-root input": {
      fontSize: "12px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
    "& .Mui-disabled": {
      backgroundColor: "#C9C9C9",
      cursor: "not-allowed",
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      color: "#fff",
    },
  },
}));

export {
  FilterBarStyled,
  FilterButtonWrapper,
  FilterButtonWrapperAdvancedSearch,
  FilterTabButtonWrapper,
  LeftBlock,
  RightBlock,
  CenterBlock,
  DropdownWrapper,
  SortBlock,
  DropdownWrapperSort,
  FilterBarWrapper,
  FilterBlockWrapper,
  Divider,
};
