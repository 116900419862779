import Image from "next/image";
import React, { useState } from "react";
import { City, CityIcon, StoriesStyled } from "./style";
import * as _ from "lodash";
import StoreySwiper from "../Swiper/StorySwiper";
import { SwiperSlide } from "swiper/react";
import { rgbDataURL, momentArLocale } from "../../utils/utils";
import useGetStories from "./hooks/useGetStories";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StoryPop from "./StoryPop";
import { useTranslation } from "next-i18next";
import moment from "moment";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAxios } from "../../hooks/axios";
import { event } from "../../utils/gtm";

const StoriesData = () => {
  const axios = useAxios();
  // moment.locale("ar", momentArLocale);

  const router = useRouter();
  const { t } = useTranslation("common");
  const [items, setItems] = useState([]);
  const [openStory, setOpenStory] = useState(null);
  const [allStoryEnd, setAllStoryEnd] = useState(false);
  // const [handleStoryClose, setHandleStoryClose] = useState(false);
  const { isStoryLoading, data: stories, refetch } = useGetStories(false);

  const [page, setPage] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (openStory != null) {
      if (newData.length - 1 == openStory) {
      router.push('/')
      }
      handleStoryOpen(newData[openStory]);
    }
  }, [openStory]);

  useEffect(() => {
    if (allStoryEnd && openStory != null) {
      if (newData[openStory]) {
        handleStoryOpen(newData[openStory]);
      }
    }
  }, [allStoryEnd]);

  const handleStoryClose = () => {
    fetchItems();
  };

  const fetchItems = async () => {
    setFetching(true);

    try {
      const { data } = await axios.get("stories/v1/all", {
        params: {
          page: page,
          pageSize: 14,
        }
      });

      if (data.data.results.length > 0) {
        setNewData(prevItems => [...prevItems, ...data.data.results]);
        setPage(prev => prev + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } finally {
      setFetching(false);
    }
  };

  const handleStoryOpen = (story) => {
    // console.log('storyaaaaaaaaa' ,story);
    // setOpenStory(story?.viewOrder);
    setItems([]);
    let data = [];
    _.map(story?.stories, (sty) => {
      let pick = _.pick(sty, [
        "mediaType",
        "mediaUrl",
        "creationDate",
        "playbackId",
        "usePlaybackId",
        "id",
        "isLiked",
        "linkType",
        "entityId",
        "entityImageThumbUrl",
        "viewCount"
      ]);
      pick["url"] = false;
      // pick["url"] =
      //   "https://diceyk6a7voy4.cloudfront.net/e78752a1-2e83-43fa-85ae-3d508be29366/hls/fitfest-sample-1_Ott_Hls_Ts_Avc_Aac_16x9_1280x720p_30Hz_6.0Mbps_qvbr.m3u8";
      // pick["url"] =
      //   "https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4";
      pick["type"] = pick["mediaType"] == "Video" ? "video" : "image";
      if (
        pick["mediaType"] == "Video" &&
        pick["usePlaybackId"] == true &&
        pick["playbackId"].length > 0
      ) {
        pick["url"] = `https://stream.mux.com/${pick["playbackId"]}.m3u8`;
      } else if (pick["type"] == "image") {
        pick["url"] = pick["mediaUrl"];
      } else if (pick["mediaType"] == "Video") {
        pick["url"] = pick["mediaUrl"];
      }
      pick["header"] = {
        sellerId: story?.sellerId,
        sellerUsername:story?.sellerUsername,
        heading: story?.sellerName,
        advertisementNumber: sty?.advertisementNumber,
        subheading: t("posted_by", {
          timeago: moment(sty?.lastUpdatedTime)
            .locale(router.locale == "ar" ? "ar" : "en")
            .fromNow(),
        }),
        profileImage: story?.sellerProfileUrl,
      };
      // pick["type"] = "video";
      delete pick?.mediaType;
      delete pick?.mediaUrl;
      delete pick?.creationDate;
      delete pick?.playbackId;
      delete pick?.usePlaybackId;

      if (pick["url"]) {
        data.push(pick);
      }
      // console.log('data ============================' ,data);
    });
    setItems(data);
  };
  return (
    <StoriesStyled>
      {isStoryLoading && (
        <Stack spacing={2} direction="row">
          {_.times(2, (loop) => (
            <Box key={`story-thumb-${loop}`} sx={{ width: "85px" }}>
              <Skeleton
                variant="circular"
                animation="wave"
                sx={{ width: "64px", height: "64px", mt: "25px", mb: "17px" }}
              />
            </Box>
          ))}
        </Stack>
      )}
      <StoreySwiper onReachEnd={() => {
        if (hasMore) {
          fetchItems();
        }
      }}>
        {_.map(newData, (stry, i) => {
          let truncatedUsername;
          if (stry?.sellerUsername.length <= 8) {
            truncatedUsername = stry?.sellerUsername
          } else {
            truncatedUsername = stry?.sellerUsername.slice(0, 8) + '...'
          }
          return (
            <SwiperSlide key={`story-${stry?.sellerId}-${i}`} >
              <City
                onClick={() => {
                  event({
                    action: "story_clicked",
                    params: {
                      story_id: stry.stories[0].id
                    }
                  });
                  setOpenStory(i);
                }}
              >
                <CityIcon>
                  <Image
                    fill
                    style={{ objectFit: "cover", objectPosition: "center" }}
                    src={stry?.sellerProfileUrl || DefaultProfilePic}
                    alt={t("city_icon")}
                    placeholder="blur"
                    blurDataURL={rgbDataURL(212, 241, 244)}
                  />
                </CityIcon>
                <div style={{ direction: "ltr" }}>
                  <Typography
                    variant="body2En"
                    size="extraSmall"
                    sx={{
                      maxWidth: "95px",
                      color: "midGray.main",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >

                    {`@${truncatedUsername}`}
                  </Typography>
                </div>
              </City>
            </SwiperSlide>
          );
        })}
      </StoreySwiper>
      {items.length > 0 && (
        <StoryPop
          items={items}
          setAllStoryEnd={setAllStoryEnd}
          handleStoryClose={() => {
            setOpenStory(null);
            handleStoryClose();
          }}
          setOpenStory={setOpenStory}
          openStory={openStory}
      
        />
      )}
      {/* <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={story}
        onClick={() => {
          setStory(false);
        }}
      >
        {item?.stories && (
          <div style={{ height: "100", width: "65" }}>
            {_.map(item?.stories, (story) => {
              let pick = _.pick(story, [
                "mediaType",
                "mediaUrl",
                "creationDate",
              ]);
              pick["url"] = pick["mediaUrl"];
              pick["type"] = pick["mediaType"] == "Video" ? "video" : "image";
              delete pick?.mediaType;
              delete pick?.mediaUrl;
              delete pick?.creationDate;
              setItems([...items, pick]);
           

              return (
                <Stories
                  stories={items}
                  defaultInterval={1500}
                  width={432}
                  height={768}
                />
              );
            })}
          </div>
        )}
      </Backdrop> */}
    </StoriesStyled>
  );
};

export default StoriesData;
