import React, { useState } from "react";
import Image from "next/image";
import {
  Avatar,
  Contents,
  ImageWrapper,
  PropertyOwnerCardStyled,
  Verified,
} from "./style";
import Typography from "@mui/material/Typography";
import { CustomButton, GradientButton } from "../Buttons/Buttons";
import { useTranslation } from "next-i18next";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import _ from "lodash";
import { CircularProgress, Link } from "@mui/material";
import { useAuth } from "../../../context/auth";
import { useRouter } from "next/router";
import {
  useMakeFollowAreaAndProject,
  useMakeFollowUser,
  useMakeUnFollowAreaAndProject,
  useMakeUnFollowUser,
} from "../../AgentDetails/hooks/useGetFollow";

const PropertyOwnerCard = ({ seller, index }) => {
  const avatarUrl = require("../../../../public/images/default-avatar.jpg");
  const { t } = useTranslation("common");
  const { user, isAuthenticated } = useAuth();
  const router = useRouter();
  const [followLoader, setFollowLoader] = useState();

  const { mutate: makeFollow, isLoading: followLoading } = useMakeFollowUser();
  const { mutate: makeUnFollow, isLoading: unFollowLoading } =
    useMakeUnFollowUser();

  const handelFollow = (id, follow) => {
    if (isAuthenticated) {
      if (follow == false) {
        makeFollow(id, {
          onSuccess: () => {
            setFollowLoader();
          },
        });
      } else {
        makeUnFollow(id, {
          onSuccess: () => {
            setFollowLoader();
          },
        });
      }
    } else {
      router?.push({
        pathname: "/login",
        query: { redirect: router?.pathname },
      });
    }
  };
  return (
    <PropertyOwnerCardStyled>
      <Avatar>
        <ImageWrapper>
          <Link
            href={`agent/${seller?.userName}`}
          >
            <Image
              src={
                seller?.avatarUrl ? seller?.avatarUrl : avatarUrl
              }
              alt={
                seller?.followedName ? seller?.followedName : seller?.firstName
              }
              fill
              style={{ objectFit: "cover", objectPosition: "center" }}
            />
          </Link>
        </ImageWrapper>
        <Verified
          src={require("../../../../public/images/user-verified.png")}
          alt="User verified"
        />
      </Avatar>
      <Contents>
        <Link
          href={`agent/${seller?.userName}`}
        >
          <Typography
            variant="body1"
            size="smaller"
            sx={{ color: "darkGray.main", mb: "9px", textDecoration: "italic" }}
          >
            {seller?.followedName ? seller?.followedName : seller?.firstName}
          </Typography>
        </Link>
        <div style={{ direction: "ltr", marginBottom: "40px" }}>
          {(seller?.username || seller?.userName) && (
            <Link
              href={`agent/${seller?.userName}`}
            >
              <Typography
                variant="body1"
                size="smallest"
                sx={{ color: "darkGray.main" }}
              >
                @{seller?.username ? seller?.username : seller?.userName}
              </Typography>
            </Link>
          )}
        </div>

        {seller?.isFollowing == true ? (
          <CustomButton
            wider="true"
            pill
            variant="outlined"
            onClick={() => {
              handelFollow(
                seller?.followedId ? seller?.followedId : seller?.id,
                seller?.isFollowing
              );
              setFollowLoader(index);
            }}
          >
            {(followLoading || unFollowLoading) && (
              <CircularProgress size={15} sx={{ mr: "5px" }} />
            )}
            {t("un_follow")}
          </CustomButton>
        ) : (
          <GradientButton
            wider="true"
            pill
            onClick={() => {
              handelFollow(
                seller?.followedId ? seller?.followedId : seller?.id,
                seller?.isFollowing
              );
              setFollowLoader(index);
            }}
          >
            {(followLoading || unFollowLoading) && (
              <CircularProgress size={15} sx={{ mr: "5px" }} color="white" />
            )}
            {t("follow")}
          </GradientButton>
        )}

        {/* {followLoader == index ? (
            <CircularProgress size={15} sx={{ mr: "5px" }} color="white" />
          ) : seller?.isFollowing == true ? (
            t("un_follow")
          ) : (
            t("follow")
          )} */}
      </Contents>
    </PropertyOwnerCardStyled>
  );
};

export default PropertyOwnerCard;
