/* eslint-disable react-hooks/rules-of-hooks */
import Image from "next/image";
import { useState, useRef, useEffect } from "react";
import { WithSeeMore } from "react-insta-stories";
import ReactPlayer from "react-player";
import WithCustomHeader from "./withCustomHeader";
import FavoriteSharpIcon from "@mui/icons-material/FavoriteSharp";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IconButton from "@mui/material/IconButton";
import useMakeLikeAndUnLike from "../../components/Stories/hooks/useMakeLikeAndUnLike";
import { useAuth } from "../../context/auth";
import { useRouter } from "next/router";
import { InlineShareButtons } from "sharethis-reactjs";
import { rgbDataURL, WEB_PATH } from "../utils";
import shareIcon from "../../../public/images/share-icon.png";
import { useTranslation } from "next-i18next";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import {
  CopyIcon,
  CopyIconWrapper,
} from "../../components/PropertyCard/components/Actions/style";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import {
  AddLinkWrapper,
  FavButtonWrapper,
  ProfileAvatarWrapper,
} from "./style";

import { Typography } from "@mui/material";
import Link from "next/link";
import { AiOutlineEye } from "react-icons/ai";
import { event } from "../gtm";

export const renderer = ({
  story,
  action,
  isPaused,
  config,
  messageHandler,
}) => {
  const [storyUrl, setStoryUrl] = useState("");
  const [playing, setPlaying] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [muted, setMuted] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, copy] = useCopyToClipboard();

  const { width, height, loader, storyStyles } = config;
  const { user } = useAuth();
  const router = useRouter();
  const { t } = useTranslation("common");

  // let computedStyles = {
  //   ...styles.storyContent,
  //   ...(storyStyles || {}),
  // };

  let vid = useRef(story.id);

  useEffect(() => {
    // setStoryUrl("");
    setStoryUrl(story?.url);
    // if (story?.usePlaybackId) {
    //   setStoryUrl(`https://stream.mux.com/${story?.playbackId}.m3u8`);
    // } else {
    // }
    // setPlaying(false);
    // setLoaded(false);
    // action("pause", true);
  }, [story]);

  // useEffect(() => {
  //   if (vid.current) {
  //     if (isPaused) {
  //       setPlaying(false);
  //     } else {
  //       setPlaying(true);
  //     }
  //   }
  // }, [isPaused]);

  const onEnded = () => {
    action("pause", true);
    action("play", true);
  };

  // const onPause = () => {
  //   action("pause", true);
  // };

  const onBuffer = () => {
    // setLoaded(false);
    action("pause", true);
  };

  const onBufferEnd = () => {
    // setLoaded(false);
    action("play", true);
  };

  // const onPlay = () => {
  //   action("play", true);
  // };

  // const onStart = () => {
  //   // setPlaying(true);
  //   // setLoaded(true);
  //   // setMuted(false);
  // };

  const duration = (time) => {
    event({
      action: "time_spent",
      params: {
        duration: time
      }
    });
    messageHandler("UPDATE_VIDEO_DURATION", { duration: time });
    action("play", true);
  };

  const onReady = () => {
    // action("play");
    setLoaded(true);
    // action("play", true);
    // setPlaying(true);
  };

  const onError = (event, err) => {
    // setMuted(true);
    // setPlaying(true);
    // action("play", true);
  };

  const playerStyle = {
    width: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
  };

  const { mutate: makeReaction, isLoading: likeLoading } =
    useMakeLikeAndUnLike();

  const handleFavButtonSelect = (id, liked) => {
    if (user) {
      makeReaction(
        { id, liked },
        {
          onSuccess: () => {
            action("play", true);
          },
          onError: () => {
            action("play", true);
          },
        }
      );
    } else {
      router?.push({
        pathname: "/login",
        query: { redirect: `/` },
      });
    }
  };
  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = anchorEl ? true : false;
  const id = open ? "simple-popover" : "";
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <WithCustomHeader story={story} globalHeader={config.header}>
      <WithSeeMore story={story} action={action}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            direction: "ltr"
          }}
        >
          <ReactPlayer
            key={storyUrl}
            ref={vid}
            url={storyUrl}
            muted={false}
            playing={playing}
            playsinline={true}
            onReady={onReady}
            // onStart={onStart}
            // onPlay={onPlay}
            // onPause={onPause}
            onBuffer={onBuffer}
            onBufferEnd={onBufferEnd}
            onDuration={duration}
            onError={onError}
            onEnded={onEnded}
            width={"100%"}
            height={"auto"}
            style={playerStyle}
            // style={computedStyles}
          />
          {!loaded && (
            <div
              style={{
                width: width,
                height: height,
                position: "absolute",
                left: 0,
                top: 0,
                background: "rgba(0, 0, 0, 0.9)",
                zIndex: 9,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#ccc",
              }}
            >
              {loader || (
                <Image
                  width={50}
                  height={50}
                  src="/images/puff.svg"
                  alt="loading..."
                />
              )}
            </div>
          )}
        </div>
      </WithSeeMore>
      <FavButtonWrapper>
        <div style={{width: 50, display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", direction: "ltr"}}>
          {story.viewCount > 0 && (
            <div style={{display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: 10}}>
              <IconButton
                aria-label="vertical contained button group"
                key="share"
                //onClick={handleShareClick}
              >
                <AiOutlineEye color="#fff" size={28}/>
              </IconButton>
              <p style={{fontSize: 10, color: "#fff"}}>{story.viewCount}</p>
            </div>
          )}
          <IconButton
            key="fav_button"
            onClick={() => {
              event({
                action: "story_like",
                params: {
                  type: "video",
                  story_id: story?.id
                }
              });
              action("pause", true);
              handleFavButtonSelect(story?.id, story?.isLiked);
              story.isLiked = !story.isLiked;
            }}
          >
            <>
              {story?.isLiked == true ? (
                <FavoriteSharpIcon color="red" style={{ fontSize: "28px" }} />
              ) : (
                <FavoriteBorderIcon color="red" style={{ fontSize: "28px" }} />
              )}
            </>
          </IconButton>
          {/* <IconButton
            aria-label="vertical contained button group"
            key="share"
            onClick={handleShareClick}
          >
            <Image
              src={shareIcon}
              alt={t("alt-icon")}
              width={24}
              height={24}
              blurDataURL={rgbDataURL(255, 255, 255)}
              style={{ objectFit: "contain" }}
            />
          </IconButton> */}
        </div>
      </FavButtonWrapper>
      {story?.linkType != "None" && (
        <Link
          href={
            story?.linkType == "Project"
              ? `project/${story?.entityId}`
              : story?.linkType == "Listing"
              ? `property/${story?.entityId}`
              : `project-modal/${story?.entityId}`
          }
        >
          <div onClick={() => {
            event({
              action: "see_add_clicked",
              params: {
                owner_id: story?.entityId
              }
            });
          }}>
            <AddLinkWrapper>
              {story?.entityImageThumbUrl && (
                <ProfileAvatarWrapper>
                  <img src={story?.entityImageThumbUrl} alt="Profile picture" />
                </ProfileAvatarWrapper>
              )}
              <Typography variant="body1" size="small" sx={{ color: "#fff" }}>
                {t("see_this_add")}
              </Typography>
            </AddLinkWrapper>
          </div>
        </Link>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box sx={{ padding: "10px", display: "flex" }}>
          <InlineShareButtons
            config={{
              alignment: "center", // alignment of buttons (left, center, right)
              color: "social", // set the color of buttons (social, white)
              enabled: true, // show/hide buttons (true, false)
              font_size: 16, // font size for the buttons
              labels: null, // button labels (cta, counts, null)
              language: router?.locale === "en" ? "en" : "ar", // which language to use (see LANGUAGES)
              networks: ["whatsapp", "facebook", "twitter", "linkedin", "copy"],
              padding: 12, // padding within buttons (INTEGER)
              radius: 4, // the corner radius on each button (INTEGER)
              show_total: false,
              size: 40, // the size of each button (INTEGER)

              // OPTIONAL PARAMETERS
              //data-url:`${WEB_PATH}property/${data?.data?.id}`,
              min_count: 10,
              url: WEB_PATH,

              // username: data?.data?.sellerName,
            }}
          />
          <CopyIconWrapper
            onClick={() => {
              copy(`${WEB_PATH}`);
            }}
          >
            {value ? <CopyIcon copied /> : <CopyIcon />}
          </CopyIconWrapper>
          {/* <CopyIconWrapper
            onClick={() => {
              copy(
                data?.data?.documentType == 0
                  ? `${WEB_PATH}/property/${data?.data?.id}`
                  : `${WEB_PATH}/project/${data?.data?.id}`
              );
            }}
          >
            {value ? <CopyIcon copied /> : <CopyIcon />}

           
          </CopyIconWrapper> */}
        </Box>
      </Popover>
    </WithCustomHeader>
  );
};

const styles = {
  storyContent: {
    width: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
  },
  videoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const tester = (story) => {
  return {
    condition: story.type === "video",
    priority: 3,
  };
};

export default {
  renderer,
  tester,
};
