import React, { useState, useEffect } from "react";
import {
  CloseButtonWrapper,
  ImageWrapper,
  JoinNowWrapper,
  SpotlightStyled,
  ImageWrapperMobile
} from "./style";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { Pagination, Autoplay, EffectFade } from "swiper";
import Image from "next/image";
import PropertyImage from "../../../public/images/property-image.jpg";
import * as _ from "lodash";
import { JOIN_NOW_URL, rgbDataURL } from "../../utils/utils";
import useGetApplicationSlider from "../../hooks/useGetApplicationSlider";
import { useTranslation } from "next-i18next";
import Skeleton from "@mui/material/Skeleton";
import theme from "../../../src/utils/theme";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import { Modal } from "semantic-ui-react";
import CloseIcon from "@mui/icons-material/Close";
import { useRouter } from "next/router";
import { event } from "nextjs-google-analytics";
import { useProperty } from "../PropertyWithFilter/context";

const Spotlight = () => {
  const { t } = useTranslation("common");
  const isXs = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMd = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLg = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const {
    isStoryLoading,
    isStoryError,
    data: sliderData,
  } = useGetApplicationSlider();
  const [isJoinOpen, setIsJoinOpen] = useState(false);
  const [lang, setLang] = useState(null);
  const router = useRouter();
  const {setMapView} = useProperty();

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "700px",
    padding: "25px 20px",
    zIndex: 999,
    borderRadius: "15px",
    maxHeight: "80vh",
  };

  useEffect(() => {
    setLang(router?.locale);
  }, []);
  useEffect(() => {
    const handler = (event) => {
      if (event?.data == "success") {
        setIsJoinOpen(false);
      }
    };

    window.addEventListener("message", handler);

    //cleanup return () => window.removeEventListener("message", handler)
  }, []);

  const handleSpotlighClick = (slider) => {
    console.log(slider);
    slider.webLinkType === "External" 
      ? window.location = slider.linkUrl
      : slider.webLinkType === "Map"
      ? setMapView('map')
      : null
  }
  return (
    <SpotlightStyled>
      {!sliderData || _.isEmpty(sliderData) ? (
        <Skeleton animation="wave" variant="rectangular" height={300} />
      ) : (
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination, Autoplay, EffectFade]}
          className="mySwiper"
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          effect={"fade"}
        >
          {_.map(sliderData?.data, (slider, i) => (
            <SwiperSlide key={`spotilight-${slider?.viewOrder}-${i}`}>
              {matchesMd ? (
                <ImageWrapper 
                  //onClick={() => setIsJoinOpen(true)}
                  >
                  <Image
                    src={
                      slider?.mediaUrl
                        ? isXs
                          ? slider?.phone_MediaUrl
                          : isSm
                          ? slider?.tablet_MediaUrl
                          : isMd
                          ? slider?.desktop_MediaUrl
                          : isLg
                          ? slider?.wideScreen_MediaUrl
                          : slider?.wideScreen_MediaUrl
                        : PropertyImage
                    }
                    alt={t("property_image")}
                    fill
                    style={{ objectFit: "cover", objectPosition: "center", cursor: slider.webLinkType != 'None' ? "pointer": "default"  }}
                    blurDataURL={rgbDataURL(212, 241, 244)}
                    onClick={e => handleSpotlighClick(slider) }
                  />
                </ImageWrapper>
              ) : (
                // <a
                //   href={`${JOIN_NOW_URL}${lang}`}
                //   target="_blank"
                //   rel="noreferrer"
                // >
                  <ImageWrapperMobile>
                    <Image
                      src={slider?.mediaUrl}
                      alt={t("property_image")}
                      fill
                      style={{ objectFit: "cover",cursor: slider.webLinkType != 'None' ? "pointer": "default"  }}
                      blurDataURL={rgbDataURL(212, 241, 244)}
                      onClick={e=>handleSpotlighClick(slider)}
                    />
                  </ImageWrapperMobile>
                // </a>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      <Modal
        open={isJoinOpen}
        // onClose={handleClose}
      >
        <Box sx={modalStyle}>
          <JoinNowWrapper>
            <CloseButtonWrapper>
              <IconButton
                sx={{ p: "8px" }}
                onClick={() => {
                  setIsJoinOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </CloseButtonWrapper>
            <iframe
              src={`${JOIN_NOW_URL}${lang}`}
              width="100%"
              height={700}
              style={{ border: "none" }}
            ></iframe>
          </JoinNowWrapper>
        </Box>
      </Modal>
    </SpotlightStyled>
  );
};

export default Spotlight;
