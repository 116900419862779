import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const CustomStoryHeaderStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const Avatar = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  marginRight: theme.typography.pxToRem(13),
}));

const ImageWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  width: "35px",
  height: "35px",
  borderRadius: "50%",
  position: "relative",
  overflow: "hidden",
  boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.2)",
}));

const Verified = styled("img", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  height: "12px",
  width: "12px",
  right: 0,
  bottom: 0,
}));

export { CustomStoryHeaderStyled, Avatar, ImageWrapper, Verified };
