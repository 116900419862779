import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import Height from "@mui/icons-material/Height";

const FavButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  bottom: "20px",
  right: "5px",
  zIndex: 1000
}));
const AddLinkWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  bottom: "20px",
  left: 5,
  zIndex: 1000,
  padding: "6px 14px",
  backgroundColor: "rgba(0,0,0, 0.7)",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
}));

const ProfileAvatarWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  width: "35px",
  height: "35px",
  borderRadius: "50%",
  overflow: "hidden",
  backgroundColor: "aliceblue",
  marginRight: "10px",
  "& img": {
    objectFit: "cover",
    objectPosition: "center",
    height: "100%",
    width: "100%",
  },
}));

export { FavButtonWrapper, AddLinkWrapper, ProfileAvatarWrapper };
