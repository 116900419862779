import React, { useState, useEffect } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  CenterBlock,
  Divider,
  DropdownWrapper,
  DropdownWrapperSort,
  FilterBarStyled,
  FilterBarWrapper,
  FilterBlockWrapper,
  FilterButtonWrapper,
  FilterTabButtonWrapper,
  LeftBlock,
  RightBlock,
  SortBlock,
} from "./style";
import { Button } from "semantic-ui-react";
import { CustomAutoComplete } from "../AdvancedSearchHeader/components/AdvancedSearch/style";
import TextField from "@mui/material/TextField";
import { useTranslation } from "next-i18next";
import Stack from "@mui/system/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useProperty } from "../PropertyWithFilter/context";
import { Box, TableSortLabel, useMediaQuery } from "@mui/material";
import Image from "next/image";
import theme from "../../utils/theme";
import TuneIcon from "@mui/icons-material/Tune";
import { useAuth } from "../../context/auth";
import _ from "lodash";
import Cookies from "js-cookie";
import { FaSlack } from "react-icons/fa";
import { PropertyIcon } from "../common/BuildingTypeTabs/style";
import vector from "./icons/vector.svg"
// import { TableSortLabel } from "@mui/material";
import { useRouter } from "next/router";
const FilterBar = ({
  followingData,
  setFollowingData,
  setPropertyData,
  setHomePage,
  typeVisible,
  homeRefetch,
}) => {
  const {
    listingType,
    setListingType,
    setPropertyType,
    propertyType,
    orderBy,
    setOrderBy,
    setSellerIds,
    handleClear,
    setMapView,
    mapView,
    tab,
    setTab,
    orderByButton,
    setOrderByButton,
    setHomePageNumber,
    homePageNumber,
  } = useProperty();
  const router = useRouter()
  const { t } = useTranslation("common");
  const [sortByValue, setSortByValue] = useState(orderByButton);
  const [sortBy, setSortBy] = useState(orderByButton ? orderByButton : "date");
  const [ascOrDec, setAscOrDec] = useState();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [filterBarVisible, setFilterBarVisible] = useState(false);
  const [orderDirection, setorderDirection] = useState("desc");
  const [order, setOrder] = useState("desc");
  const [currentProperty, setCurrentProperty] = useState(null);


  const sortByOptions = [
    { label: t("area"), value: "area" },
    { label: t("date"), value: "date" },
    { label: t("price"), value: "price" },
  ];
  const propertyTypes = [
    { label: t("apartment"), value: "10" },
    { label: t("building"), value: "20" },
    { label: t("villa"), value: "30" },
    { label: t("land"), value: "40" },
    { label: t("roof"), value: "50" },
    { label: t("rest_house"), value: "60" },
    { label: t("Office"), value: "70" },
    { label: t("store"), value: "80" },
    { label: t("floor"), value: "85" },
    { label: t("Studio"), value: "90" },
    { label: t("Room"), value: "95" },
    { label: t("compound"), value: "100" },
    { label: t("tower"), value: "105" },
    { label: t("Gallery"), value: "110" },
    { label: t("Warehouse"), value: "115" },
    { label: t("Kiosk"), value: "120" },
    { label: t("cinema"), value: "125" },
    { label: t("hotel"), value: "130" },
    { label: t("parking_lot"), value: "135" },
    { label: t("workshop"), value: "140" },
    { label: t("atm"), value: "145" },
    { label: t("factory"), value: "150" },
    { label: t("school"), value: "155" },
    { label: t("hospital"), value: "160" },
    // { label: t("electric_power_station"), value: "165" },
    { label: t("telecom_tower"), value: "170" },
    { label: t("gas_station"), value: "175" },
    { label: t("Farm"), value: "180" },
  ];

  useEffect(() => {
    getCookies()
    if (homePageNumber == 0) {
      homeRefetch();
    }
  }, []);

  async function getCookies() {
    return new Promise((resolve) => {
      // Simulating an asynchronous operation, e.g., fetching data
      setTimeout(() => {
        setListingType(Cookies.get("listingType") === "null" ? null : Cookies.get("listingType"));
        setOrderByButton(Cookies.get("orderByButton") === "null" ? 'date' : Cookies.get("orderByButton"));
        setOrderBy(Cookies.get("orderByButton") === "null" ? 'date' : Cookies.get("orderByButton") + " " + order);
        setPropertyType(Cookies.get("propertyType") === "null" ? null : Cookies.get("propertyType"))
        if (Cookies.get("listingType") !== "null" && Cookies.get("listingType")) {
          setHomePageNumber(0)
          setHomePage(0)
        }
        resolve({
          listingType: Cookies.get("listingType"),
          orderByButton: Cookies.get("orderByButton"),
          propertyType: Cookies.get("propertyType") || null
        });
      }, 1000);
      homeRefetch(); // Simulating a delay of 1 second, replace this with your actual async operation
    });
  }

  useEffect(() => {
    const defaultProperty = _.find(propertyTypes, {
      value: propertyType ? propertyType : null,
    });
    setCurrentProperty(defaultProperty || null);
  }, [propertyType]);

  const currentOrderType = _.find(sortByOptions, {
    value: orderByButton ? orderByButton : 'date',
  });

  const createSortHandle = (event, property) => {
    const isAsc = ascOrDec === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setorderDirection(isAsc ? "desc" : "asc");
  };

  useEffect(() => {
    setOrderBy(sortBy + " " + order);
  }, [order || orderDirection]);

  function handleClearOptions() {
    if (propertyType == null) {
      homeRefetch();
    }
  }

  useEffect(() => {
    // console.log("followingTab => ", Cookies.get("followingTab"));
    if (Cookies.get("followingTab") == undefined) {
      setTab("all");
      // setListingType(null);
      setPropertyType(null);
      setPropertyData(true);
      setFollowingData(false);
      // console.log('test111' , listingType);
      // Cookies.set("listingType", null);
      // Cookies.set("propertyType", null)
      // Cookies.set("orderByButton", null)
    } else {
      setTab("following");
      // Cookies.set("listingType", null);
      // Cookies.set("propertyType", null)
      // Cookies.set("orderByButton", null)
      setListingType(null);
      setPropertyType(null);
      setFollowingData(true);
      setPropertyData(false);
    }
  }, [])

  return (
    <FilterBarWrapper filterBarVisible={filterBarVisible}>
      <FilterBlockWrapper>
        <DropdownWrapper>
          {mapView == "list" && typeVisible == true && (
            <CustomAutoComplete
              disabled={mapView == "map" || typeVisible == false}
              name="sort_by_pro"
              key="sort_by_pro"
              // disablePortal
              noOptionsText={t("no_options")}
              id="combo-box-sort"
              options={propertyTypes}
              value={currentProperty}
              sx={{
                width: "100%",
              }}
              size="small"
              renderInput={(params) => (
                <TextField {...params} placeholder={t("type")} />
              )}
              onChange={(event, value) => {
                // setPropertyDropDown(value?.value || null);
                setHomePage(0);
                setHomePageNumber(0);
                setPropertyType(value?.value || null);
                Cookies.set("propertyType", value?.value || null)

                // setPropertyValue(value?.value);
              }}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "clear") {
                  handleClearOptions();
                }
              }}
            />
          )}
        </DropdownWrapper>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ mr: "5px" }}>{t("filter")}</Typography>
          <IconButton
            onClick={() => setFilterBarVisible(!filterBarVisible)}
            sx={{ borderRadius: "8px", border: "1px solid #EBEBEB", p: "7px" }}
          >
            <TuneIcon />
          </IconButton>
        </Box>
      </FilterBlockWrapper>
      <Box sx={{ textAlign: "center", display: ["block", "block", "none"] }}>
        <FilterTabButtonWrapper>
          <ToggleButtonGroup
            value={tab}
            exclusive
            onChange={(e, value) => {
              if (followingData == false && mapView == "map") {
                setMapView("list");
              }
            }}
            aria-label="ViewSwitch"
            variant="contained"
          >
            <ToggleButton
              value="all"
              onClick={() => {
                setTab("all");
                setListingType(null);
                setPropertyType(null);
                setPropertyData(true);
                setFollowingData(false);
                Cookies.set("listingType", null);
                Cookies.set("propertyType", null)
                Cookies.set("orderByButton", null)


              }}
            >
              {t("all")}
            </ToggleButton>
            <ToggleButton
              value="following"
              onClick={() => {
                setTab("following");
                setListingType("sale");
                Cookies.set("propertyType", null)
                Cookies.set("propertyType", null)
                Cookies.set("orderByButton", null)
                setPropertyType(null);
                setFollowingData(true);
                setPropertyData(false);
              }}
            >
              {t("following")}
            </ToggleButton>
          </ToggleButtonGroup>
        </FilterTabButtonWrapper>
      </Box>
      <Divider filterBarVisible={filterBarVisible} />
      <FilterBarStyled filterBarVisible={filterBarVisible}>
        <LeftBlock>
          <Stack
            direction={"row"}
            spacing={mapView == "list" ? 3 : 0}
            justifyContent={{
              xs: "center",
              sm: "center",
              md: "space-between",
            }}
            alignItems={"center"}
          >
            {" "}
            <Box sx={{ display: ["none", "none", "block"] }}>
              <FilterButtonWrapper>
                {typeVisible == true && (
                  <ToggleButtonGroup
                    value={mapView}
                    exclusive={true}
                    sx={{
                      cursor: typeVisible == false ? "not-allowed" : "default",
                    }}
                    onChange={(e, value) => {
                      if (value == null) {
                        return;
                      }
                      if (value == "list") {
                        homeRefetch();
                      }
                      followingData == true ? setTab("all") : setMapView(value);

                      if (followingData == true) {
                        setPropertyData(true);
                        setFollowingData(false);
                      }
                    }}
                    aria-label="ViewSwitch"
                    variant="contained"
                  >
                    <ToggleButton value="list" disabled={typeVisible == false}>
                      {t("list")}
                    </ToggleButton>
                    <ToggleButton value="map" disabled={typeVisible == false}>
                      {t("map")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              </FilterButtonWrapper>
            </Box>
            <Box sx={{ display: ["none", "none", "block"] }}>
              {mapView == "list" && typeVisible == true && (
                <DropdownWrapper>
                  <CustomAutoComplete
                    disabled={mapView == "map" || typeVisible == false}
                    name="sort_by_pro"
                    key="sort_by_pro"
                    // disablePortal
                    noOptionsText={t("no_options")}
                    id="combo-box-sort"
                    options={propertyTypes}
                    value={currentProperty}
                    sx={{
                      width: "100%",
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} placeholder={t("type")} />
                    )}
                    onChange={(event, value) => {
                      // setPropertyDropDown(value?.value || null);
                      setHomePage(0);
                      setHomePageNumber(0);
                      setPropertyType(value?.value || null);
                      Cookies.set("propertyType", value?.value || null)
                      // setPropertyValue(value?.value);
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "clear") {
                        handleClearOptions();
                      }
                    }}
                  />
                </DropdownWrapper>
              )}
            </Box>
          </Stack>
        </LeftBlock>
        <CenterBlock>
          <FilterTabButtonWrapper>
            <ToggleButtonGroup
              value={tab}
              exclusive
              onChange={(e, value) => {
                if (followingData == false && mapView == "map") {
                  setMapView("list");
                }
              }}
              aria-label="ViewSwitch"
              variant="contained"
            >
              <ToggleButton
                value="all"
                onClick={() => {
                  Cookies.remove("followingTab");
                  setTab("all");
                  setListingType(null);
                  Cookies.set("listingType", null);
                  Cookies.set("propertyType", null)
                  Cookies.set("orderByButton", null)

                  setPropertyType(null);
                  setPropertyData(true);
                  setFollowingData(false);
                }}
              >
                {t("all")}
              </ToggleButton>
              <ToggleButton
                value="following"
                onClick={() => {
                  Cookies.set("listingType", null);
                  Cookies.set("propertyType", null)
                  Cookies.set("orderByButton", null)
                  Cookies.set("followingTab", "following");
                  setTab("following");
                  setListingType("sale");
                  setPropertyType(null);
                  setFollowingData(true);
                  setPropertyData(false);
                }}
              >
                {t("following")}
              </ToggleButton>
            </ToggleButtonGroup>
          </FilterTabButtonWrapper>
        </CenterBlock>
        <RightBlock>
          <Stack
            direction={"row"}
            spacing={3}
            justifyContent={{
              xs: "center",
              sm: "center",
              md: "space-between",
            }}
            alignItems={"center"}
          >
            <FilterButtonWrapper>
              {typeVisible == true && (
                <ToggleButtonGroup
                  value={listingType != null ? (listingType == "rent" ? "rent" : "buy") : null}
                  exclusive
                  sx={{
                    cursor: typeVisible == false ? "not-allowed" : "default",
                  }}
                  aria-label="ViewSwitch"
                  variant="contained"
                >
                  <ToggleButton
                    value="buy"
                    disabled={typeVisible == false}
                    onClick={() => {
                      setHomePage(0);
                      setHomePageNumber(0);
                      setListingType("sale");
                      Cookies.set("listingType", "sale");

                    }}
                  >
                    {t("buy")}
                  </ToggleButton>
                  <ToggleButton
                    value="rent"
                    disabled={typeVisible == false}
                    onClick={() => {
                      setHomePage(0);
                      setHomePageNumber(0);
                      setListingType("rent");
                      Cookies.set("listingType", "rent");
                    }}
                  >
                    {t("rent")}
                  </ToggleButton>
                  <ToggleButton
                    value="null"
                    disabled={typeVisible == false}
                    onClick={() => {
                      // setHomePage(0);
                      // setHomePageNumber(0);
                      // setListingType("null");
                      // Cookies.set("listingType", "null");
                      setListingType(null);
                      Cookies.set("listingType", null);
                      Cookies.set("propertyType", null)
                      Cookies.set("orderByButton", null)
                      setPropertyType(null);
                      setPropertyData(true);
                    }}
                    style={{ width: "1.5px" }}
                  >
                    <Image alt="map" width={19} height={17.01} top={3} src={vector} />
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </FilterButtonWrapper>
            {/* {mapView == "list" && typeVisible == falsee && ( */}
            {mapView == "list" && typeVisible == true && (
              <SortBlock>
                <Typography variant="body1" sx={{ mr: "8px" }}>
                  {t("sort_by")}
                </Typography>
                <DropdownWrapperSort>
                  <CustomAutoComplete
                    dir="rtl"
                    disabled={mapView == "map" || typeVisible == false}
                    name="sort_by"
                    key="sort_by"
                    disableClearable
                    disablePortal
                    id="combo-box-sort"
                    options={sortByOptions}
                    value={currentOrderType}
                    noOptionsText={t("no_options")}
                    sx={{
                      width: "100%",
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} placeholder={t("price")} />
                    )}
                    onChange={(event, value) => {
                      setSortBy(value?.value);
                      setSortByValue(value?.label);
                      setOrderByButton(value?.value);
                      Cookies.set("orderByButton", value?.value);
                      setOrderBy(value?.value + " " + order);
                    }}
                  />
                </DropdownWrapperSort>

                <IconButton
                  disabled={mapView == "map" || typeVisible == false}
                  sx={{
                    p: 0,
                    backgroundColor: "transparent",
                    ml: "8px",
                    cursor:
                      mapView == "map" || typeVisible == false
                        ? "not-allowed"
                        : "default",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  onClick={() => {
                    createSortHandle();
                  }}
                >
                  <Image
                    src={require("./icons/sort-icon.svg")}
                    width={matches ? 40 : 35}
                    height={matches ? 40 : 35}
                    style={{
                      transform: order == "desc" ? "scaleY(1)" : "scaleY(-1)",
                      filter:
                        mapView == "map" || typeVisible == false
                          ? "grayscale(1)"
                          : "grayscale(0)",
                    }}
                  />
                </IconButton>
              </SortBlock>
            )}
          </Stack>
        </RightBlock>
      </FilterBarStyled>
    </FilterBarWrapper>
  );
};

export default FilterBar;
