import React from "react";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import {
  Avatar,
  CustomStoryHeaderStyled,
  ImageWrapper,
  Verified,
} from "./style";
import Link from "next/link";
import { useTranslation } from "next-i18next";

const CustomStoryHeader = ({ profileImage, sellerUsername , heading, subheading, sellerId, advertisementNumber }) => {
  const { t } = useTranslation("common");
  return (
    <CustomStoryHeaderStyled>
      <Avatar>
        <ImageWrapper>
          <Image
            src={profileImage}
            alt="Profile image"
            fill
            style={{ objectFit: "cover", objectPosition: "center" }}
          />
        </ImageWrapper>
        <Verified src={require("../../../../public/images/user-verified.png")} />
      </Avatar>
      <div>
        <Typography
          variant="body1"
          size="smaller"
          sx={{ color: "white.main", mr: "24px" }}
        >
          <Link href={`/agent/${sellerUsername}`}>{heading}</Link>
        </Typography>
        <Typography
          variant="normal"
          size="smaller"
          sx={{ color: "white.main", mr: "24px" }}
          style={{marginTop: 3, fontSize: 10}}
          >
          {t("advertisement_number", {adnum: advertisementNumber})}
        </Typography>
      </div>
      <Typography variant="body1" size="tiny" sx={{ color: "white.main" }}>
        {subheading}
      </Typography>
    </CustomStoryHeaderStyled>
  )
};

const styles = {
  main: {
    display: "flex",
    alignItems: "center",
  },
  img: {
    width: 40,
    height: 40,
    borderRadius: 40,
    marginRight: 10,
    filter: "drop-shadow(0 0px 2px rgba(0, 0, 0, 0.5))",
    border: "2px solid rgba(255, 255, 255, 0.8)",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    filter: "drop-shadow(0 0px 3px rgba(0, 0, 0, 0.9))",
  },
  heading: {
    fontSize: "1rem",
    color: "rgba(255, 255, 255, 0.9)",
    margin: 0,
    marginBottom: 2,
  },
  subheading: {
    fontSize: "0.6rem",
    color: "rgba(255, 255, 255, 0.8)",
    margin: 0,
  },
};

export default CustomStoryHeader;
