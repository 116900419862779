import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { Container } from "@mui/material";
import { MenuItem, Select } from "@mui/material";

const FilteredUsersStyled = styled("section", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  //marginBottom: "150px",
}));

const ContainerStyled = styled(Container, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  padding: "0 !important",
}));

const FilterTabs = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.typography.pxToRem(20),
  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.typography.pxToRem(30),
  },
  [theme.breakpoints.up("md")]: {
    marginBottom: theme.typography.pxToRem(40),
  },
  [theme.breakpoints.up("lg")]: {
    marginBottom: theme.typography.pxToRem(80),
  },
}));

const FilterTab = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({}));

const FilterTabTitle = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "active",
})(({ theme, active }) => ({
  padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)}`,
  cursor: "pointer",
  "&:hover h4": {
    color: theme.palette.primary.main,
  },
  "& h4": {
    transition: "color 0.2s ease",
    color: active ? theme.palette.primary.main : theme.palette.midGray.main,
  },
  [theme.breakpoints.up("sm")]: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(55)}`,
  },
}));

const SelectStyled = styled(Select, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "active",
})(({ theme, active }) => ({}));

export {
  FilteredUsersStyled,
  ContainerStyled,
  FilterTabs,
  FilterTab,
  FilterTabTitle,
  SelectStyled,
};
