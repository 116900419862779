import Image from "next/image";
import { Grid, Box } from "@mui/material";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { mapNumFormatter } from "../../utils/utils";
import { IoIosCloseCircleOutline } from 'react-icons/io';

export const InfoWindowComponent = ({
    property,
    setDetailViewSt,
    setNearbyPlaces,
    setSelectedFacility,
    clearDirectionRenderer,
}) => {

    const { t } = useTranslation("common");
    return (
        <Box sx={{ boxShadow: 3 }} style={{ width: 350, overflow: 'hidden', backgroundColor: "#fff", padding: 10, borderRadius: 15 }}>
            <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                    <div className="property-image">
                        <Image src={property?.media?.[0]?.imageThumbUrl} alt="Property image" width={100} height={100} style={{ borderRadius: 10 }} />
                    </div>
                </Grid>
                <Grid item xs={8} md={8}>
                    <div className="details">
                        <div className="address" style={{ marginBottom: 5 }}>
                            <Grid container spacing={0}>
                                <Grid xs={4} md={4}>
                                    <span className="settings">
                                        <span className="ellipse">&#10004;</span>
                                        <span className={`ellipse ${property?.virtualTourUrl != true ? "disabled" : ""}`}>3D</span>
                                        <span className={`ellipse ${!property?.inspectionReportUrl ? "disabled" : ""}`}><Image src="/images/wrench.png" alt="settings" width="8" height="8" /></span>
                                    </span>
                                </Grid>
                                <Grid xs={6} md={6}>
                                    <strong>
                                        {property?.documentType == 1 ? property?.title : t("type-sale-rent", {
                                            propertyType: t(property?.propertyType),
                                            listingType: t(property?.listingType),
                                        })}
                                    </strong>
                                </Grid>
                                <Grid xs={2} md={2} style={{ textAlign: 'right' }}>
                                    <IoIosCloseCircleOutline
                                        size={20}
                                        onClick={() => {
                                            setDetailViewSt(null);
                                            setNearbyPlaces([]);
                                            clearDirectionRenderer();
                                            setSelectedFacility(null);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginBottom: 15, fontWeight: 'bold', fontSize: 14 }}>
                            {property?.documentType == 1 ? (
                                <>
                                    <p style={{ marginBottom: 5 }}>{t("price_start")}</p>
                                    {t("price_sar", { price: mapNumFormatter(property?.price) })}
                                </>
                            ) : t("price_sar", { price: mapNumFormatter(property?.price) })}
                        </div>
                        {property.documentType != 1 && (
                            <Grid container spacing={0.5}>
                                {property?.lotSize && (
                                    <Grid xs={3} md={3}>
                                        <span>{property?.lotSize}</span>&nbsp;
                                        <span class="fa-sr-only">
                                            <Image src="/images/icons/AreaIcon.svg" alt="Area" width="12" height="12" />
                                        </span>
                                    </Grid>
                                )}
                                {property?.numberBedrooms && (
                                    <Grid xs={3} md={3}>
                                        <span>{property?.numberBedrooms}</span>&nbsp;
                                        <span class="fa-sr-only">
                                            <Image src="/images/icons/BedRoom.svg" alt="Bedroom" width="12" height="12" />
                                        </span>
                                    </Grid>
                                )}
                                {property?.numberBathrooms && (
                                    <Grid xs={3} md={3}>
                                        <span>{property?.numberBathrooms}</span>&nbsp;
                                        <span class="fa-sr-only">
                                            <Image src="/images/icons/BathRoom.svg" alt="BathRoom" width="12" height="12" />
                                        </span>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        <div className="property-link" style={{ textAlign: 'center', width: 100, marginTop: 10 }}>
                            <Link href={`/${property?.documentType == 1 ? "project" : "property"}/${property?.id}`}>
                                <span className="view-link" >
                                    {t("details")}
                                </span>
                            </Link>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}