import React, { useRef,useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import _, { property, sortBy } from "lodash";
import PropertyCard from "../../PropertyCard/PropertyCard";
import { useProperty } from "../context";
import BoxWithAvatar from "../../common/Skeletons/BoxWithAvatar";
import { FilterTabs, FilterTabTitle } from "../style";
import { useTranslation } from "next-i18next";
import NoItem from "../../common/NoItem/NoItem";
import { useState } from "react";
import PropertyOwnerCard from "../../common/PropertyOwnerCard";
import useGetSellers from "../../../hooks/useGetSellers";
import Typography from "@mui/material/Typography";
import {
  useGetFollowingPropertyData,
  useGetHomeData,
} from "../../AgentDetails/hooks/useGetFollow";
import { useAuth } from "../../../context/auth";
import { useRouter } from "next/router";
import { CustomAutoComplete } from "../../AdvancedSearchHeader/components/AdvancedSearch/style";
import {
  Button,
  TableSortLabel,
  TextField,
  useMediaQuery,
} from "@mui/material";
import useGetProperties from "../hooks/useGetProperties";
import { DropdownWrapper } from "./style";
import { CustomButton } from "../../common/Buttons/Buttons";
import FilterBar from "../../FilterBar/FilterBar";
import PropertyMapComponent from "../../PropertyMapComponent/PropertyMapComponent";
import { PropertyMapViewStyled } from "../../PropertyMapView/style";
import Cookies from "js-cookie";
import theme from "../../../utils/theme";
import useSellers from "../../../hooks/useSellers";
import { event } from "../../../utils/gtm";
import { isIOS } from "react-device-detect";

const PropertyList = ({ search }) => {
  const { t } = useTranslation("common");
  const { isAuthenticated } = useAuth();

  const router = useRouter();

  const {
    listingType,
    propertyType,
    orderBy,
    handleClear,
    mapView,
    followingData,
    setFollowingData,
    propertyData,
    setPropertyData,
    homePageNumber,
    setHomePageNumber,
  } = useProperty();
  const [page, setPage] = useState(0);
  const [sellerPage, setSellerPage] = useState(0);
  const [homePage, setHomePage] = useState(homePageNumber ? homePageNumber : 0);
  const mediumMatches = useMediaQuery(theme.breakpoints.up("md"));

  const pageSize = 12;
  const {
    data: homeAllData,
    isLoading: homeAllLoading,
    isFetching,
    refetch: homeRefetch,
  } = useGetHomeData({
    pageSize,
    homePage,
    listingType,
    propertyType,
    orderBy,
  });

  const windowSize = useRef([0, 0]); // Initialize with arbitrary values

  useEffect(() => {
    if (typeof window !== 'undefined') { // Check if window object is available
      windowSize.current = [window.innerWidth, window.innerHeight];

      const handleResize = () => {
        windowSize.current = [window.innerWidth, window.innerHeight];
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const handleScrollToTop = () => {
    if (typeof window !== 'undefined') { // Check if window object is available
      window.scrollTo(0, 0);
    }
  };

  const calculateTopValue = () => {
    const [width, height] = windowSize.current;
    if (width <= 375) {
      return 326;
    } else if (width <= 428) {
      return 330;
    } else if (width <= 576) {
      return 335;
    } else if (width <= 768) {
      return 311;
    } else if (width <= 992) {
      return 330;
    } else {
      return 620;
    }
  };
  const handleHomePageChange = (event, value) => {
    handleScrollToTop()
    setHomePage(value - 1);
    setHomePageNumber(value - 1);
  };

  useEffect(() => {
    if (mapView == "map") {
      event({
        action: "map_page_visited",
        params: {}
      });
      router.push("/?view=map", undefined, { shallow: true });
    } else {
      router.push("/", undefined, { shallow: true });
    }
  }, [mapView]);

  useEffect(() => {

    if (homePage != null) {
      homeRefetch();
    }
  }, [homePage]);

  useEffect(() => {
   
    if (homePageNumber < 1) {
      setHomePage(0);
    }
  }, [homePageNumber]);

  useEffect(() => {
    if (homePageNumber != null) {
      setHomePage(homePageNumber);
    }
  }, []);

  const followPerPage = 12;
  const filter = {
    followPerPage,
    page,
  };

  const filters = {
    pageSize,
    page: page,
    listingType,
    propertyType: propertyType,
    orderBy: orderBy,
    profile: "seller",
  };

  const {
    data: followedData,
    isLoading: followLoading,
    isError: isFollowError,
    refetch: followRefetch,
    isFetching: isFollowFetching,
  } = useGetFollowingPropertyData(
    {
      filters,
    },
    isAuthenticated && followingData == true
  );
  useEffect(() => {
    if (isAuthenticated && followingData == true) {
      setPage(0);
      followRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (isAuthenticated && followingData == true) {
      // setPage(0);
      followRefetch();
    }
  }, [listingType]);

  useEffect(() => {
    if (listingType || listingType == null) {
      followingData == true ? followRefetch() : homeRefetch();
    }
  }, [listingType]);

  useEffect(() => {
    if (propertyType || propertyType == null) {
      followingData == true ? followRefetch() : homeRefetch();
    }
  }, [propertyType]);

  useEffect(() => {
    if (orderBy || orderBy == "price desc") {
      followingData == true ? followRefetch() : homeRefetch();
    }
  }, [orderBy]);

  const {
    data: sellerList,
    isLoading: isSellerLoading,
    refetch: sellerHomeList,
  } = useSellers(
    {
      filter: {
        page: sellerPage,
        pageSize: 12
      }
    },
    isFollowError || !isAuthenticated
  );

  useEffect(() => {
    if (sellerPage < 1) {
      setSellerPage(0);
    }
  }, []);

  const handleFollowPageChange = (event, value) => {
    
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page

    setPage(value - 1);
  };
  const handleSellerPageChange = (event, value) => {
    handleScrollToTop()

    // window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page

    let newPage = value - 1;
    setSellerPage(newPage);

    setTimeout(() => {
      sellerHomeList();
    }, 100)
  };
  // console.log('homeAllData?.data?.results' ,homeAllData?.data?.results);
  const schema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "name": "property",
    "itemListElement": homeAllData?.data?.results?.map((item, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": "https://sanadak.sa/",
      "name": item?.title,
      "item": item?.sellerProfileUrl,
      "offers": {
        "@type": "Offer",
        "price": item?.price,
        "priceCurrency": "SAR",
      },
      "geo": {
        "@type": "PostalAddress",
        "longitude": item?.longitude,
        "latitude": item?.latitude,
      },
      // "numberBedrooms": item?.numberBedrooms,
      "numberACs": item?.numberACs,
      "numberElevators": item?.numberElevators,
      "numberParkingAreas": item?.numberParkingAreas,
      "direction": item?.propertyFacingDirection,
      "address": item?.city + "," + item?.district,
      "streetWidth": item?.streetWidth,
      "area": item?.lotSize,
    })),
  };

  const typeVisible =
    followedData?.data?.totalResults > 0 || followingData == false
      ? true
      : false;

  return (
    <>
      {!search ? (
        mediumMatches ? (
          <FilterBar
            followingData={followingData}
            setFollowingData={setFollowingData}
            setPropertyData={setPropertyData}
            typeVisible={typeVisible}
            setHomePage={setHomePage}
            homeRefetch={homeRefetch}
          />
        ) : mapView == "list" ? (
          <FilterBar
            followingData={followingData}
            setFollowingData={setFollowingData}
            setPropertyData={setPropertyData}
            typeVisible={typeVisible}
            setHomePage={setHomePage}
            homeRefetch={homeRefetch}
          />
        ) : null
      ) : null}
      {propertyData == true && mapView == "list" && (
        <Box>
          {homeAllLoading || isFetching ? (
            <Grid container spacing={4}>
              <>
                {_.times(8, (loop) => (
                  <BoxWithAvatar key={`skel-propert-${loop}`} />
                ))}
              </>
            </Grid>
          ) : _.isEmpty(homeAllData?.data?.results) ? (
            <NoItem centered title="no-listing-available-yet" />
          ) : (
            <>
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
              />
              <Grid container spacing={2} key="fololow">
                {_.map(homeAllData?.data?.results, (property) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={`property-g-${property?.id}`}
                  >
                    <PropertyCard data={property} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          {!homeAllLoading && homeAllData?.data?.totalResults > pageSize && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: ["20px", "40px"],
                //mb: ["100px", "120px", "150px", "215px"],
              }}
            >
              <Pagination
                count={Math.ceil(homeAllData?.data?.totalResults / pageSize)}
                page={parseInt(homePage) + 1}
                shape="rounded"
                onChange={handleHomePageChange}
              />
            </Box>
          )}
        </Box>
      )}
      {(propertyData == true || followingData == true) && mapView == "map" && (
        <Box sx={{ mx: "15px" }}>
          <PropertyMapComponent />
        </Box>
      )}

      {isFollowFetching ? (
        <Grid container spacing={4}>
          <>
            {_.times(8, (loop) => (
              <BoxWithAvatar key={`skel-propert-${loop}`} />
            ))}
          </>
        </Grid>
      ) : (
        followingData == true &&
        mapView == "list" &&
        (isAuthenticated && followedData?.data?.totalResults >= 0 ? (
          <Box>
            <div style={{ height: "30px" }}></div>
            {_.isEmpty(followedData?.data?.results) ? (
              <Grid container spacing={4}>
                {_.map(sellerList?.data.results, (seller, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={`property-s-${seller?.id}`}
                  >
                    <PropertyOwnerCard seller={seller} index={index} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid container spacing={2}>
                {_.map(followedData?.data?.results, (property) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={`property-${property?.id}`}
                  >
                    <PropertyCard data={property} />
                  </Grid>
                ))}
              </Grid>
            )}

            {!followLoading && followedData?.data?.totalResults > pageSize && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: ["20px", "40px"],
                  mb: ["100px", "120px", "150px", "215px"],
                }}
              >
                <Pagination
                  count={Math.ceil(followedData?.data?.totalResults / pageSize)}
                  page={page + 1}
                  shape="rounded"
                  onChange={handleFollowPageChange}
                />
              </Box>
            )}
          </Box>
        ) : (
          <div style={{ marginTop: "30px" }}>
            
            {isSellerLoading || followLoading || isFollowFetching ? (
              <Grid container spacing={4}>
                <>
                  {_.times(8, (loop) => (
                    <BoxWithAvatar key={`skel-propert-${loop}`} />
                  ))}
                </>
              </Grid>
            ) : _.isEmpty(sellerList?.data.results) ? (
              <NoItem centered title="no-listing-available-yet" />
            ) : (
              <>
                <Grid container spacing={4}>
                  {_.map(sellerList?.data.results, (seller, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      key={`property-s-${seller?.id}`}
                    >
                      <PropertyOwnerCard seller={seller} index={index} />
                    </Grid>
                  ))}
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: ["20px", "40px"],
                    //mb: ["100px", "120px", "150px", "215px"],
                  }}
                >
                  <Pagination
                    count={Math.ceil(sellerList?.data?.totalResults / 12)}
                    page={sellerPage + 1}
                    shape="rounded"
                    onChange={handleSellerPageChange}
                  />
                </Box>
              </>
            )}
          </div>
        ))
      )}
    </>
  );
};

export default PropertyList;
