import { useQuery } from "react-query";
import { useAxiosSearch } from "./axiosSearch";

//fetch all sellers
const useSellers = (filter, enabled = true) => {
  const axios = useAxiosSearch();
  return useQuery(
    ["sellerList"],
    async () => {
      const { data } = await axios.post("search/v1/profile/sellers", {
        showOwn : false,
        agentsWithAd : false,
        page: filter?.filter?.page,
        pageSize: filter?.filter?.pageSize,
      });
      return data;
    },
    { enabled: enabled }
  );
};
export default useSellers;
