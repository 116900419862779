import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const StoriesStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
}));

const City = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // justifyContent: "center",
  // width: "95px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  paddingTop: theme.typography.pxToRem(17),
  paddingBottom: theme.typography.pxToRem(17),
}));

const CityIcon = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  height: "64px",
  width: "64px",
  borderRadius: "32px",
  overflow: "hidden",
  margin: "8px",
  // boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.2)",
  border : '1px #80808040 solid',
  cursor: "pointer",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: `translateY(-3px)`,
  },
}));

export { StoriesStyled, City, CityIcon };
