import React, { useState, useRef, useEffect, useCallback } from "react";
import { GoogleMap, Marker, OverlayView, OVERLAY_MOUSE_TARGET, MarkerClusterer, LoadScript } from '@react-google-maps/api';
import Cookies from "js-cookie";
import { useProperty } from "../PropertyWithFilter/context";
import { useGetHomeDataMap } from "../AgentDetails/hooks/useGetFollow";
import { FacilityMarkers, NearByComponent, SelectedMarker, clusterMarker, listingMarkers, projectMarkers } from "./markers";
import { WholeMapComponentWrapper, ResultCountOverlay, ResultCountMinOverlay } from "./style";
import { InfoWindowComponent } from "./InfoWindowComponent";
import { InfoWrapper } from "../PropertyMapView/style";
import { CartIcon, EduIcon, GymIcon, MosqueIcon, ScopeIcon } from "../../utils/icons";
import { Paper, useMediaQuery } from "@mui/material";
import { useTranslation } from "next-i18next";
import theme from "../../utils/theme";
import PropertyInfoDrawerMap from "../PropertyMapView/components/PropertyInfoDrawer/PropertyInfoDrawerMap";
import { useAuth } from "../../context/auth";
import { useAxios } from "../../hooks/axios";
import { useRouter } from "next/router";
import { event } from "../../utils/gtm";
import { MdFullscreen, MdCloseFullscreen } from "react-icons/md";

const mapStyles = [
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
];

function centerOverlayView(width, height) {
    return {
        x: -(width / 2),
        y: -(height / 2),
    }
}

const nearByFilterTypes = [
    { icon: <CartIcon />, title: "supermarket" },
    { icon: <ScopeIcon />, title: "hospital" },
    { icon: <MosqueIcon />, title: "mosque" },
    { icon: <EduIcon />, title: "school" },
    { icon: <GymIcon />, title: "gym" },
];

const PropertyMapComponent = () => {

    const containerStyle = {
        width: '100%',
        height: '70vh'
    };
    
    const containerFullStyle = {
        width: '100%',
        height: window.innerHeight
    };

    const mapRef = useRef();
    const axios = useAxios();
    const router = useRouter();
    const { mapDrawer, setMapDrawer } = useAuth();
    const [drawerOpen, setDrawerOpen] = useState(mapDrawer ?? null);
    const { t } = useTranslation("common");

    let latitude = Cookies.get("latitude");
    let longitude = Cookies.get("longitude");

    const [useCenter, setUserCenter] = useState({
        lat: latitude && latitude != 0 ? parseFloat(latitude) : 21.5172115129313,
        lng: latitude && longitude != 0 ? parseFloat(longitude) : 39.19329641743499,
    });

    const [selectedProperty, setSelectedProperty] = useState(null);
    const [perPage, setPerPage] = useState(50);
    const [markers, setMarkers] = useState([]);
    const [dupMarkers, setDupMarkers] = useState([]); // duplicate markers
    const [center, setCenter] = useState(null);
    const [zoom, setZoom] = useState(12);
    const [nearbyFilterType, setNearbyFilterType] = useState("supermarket"); //supermarket,  hospital,  mosque,  school
    const [nearbyPlaces, setNearbyPlaces] = useState([]);
    const [renderObjects, setSenderObjects] = useState([]);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [bound, setBounds] = useState(null);
    const [isSwiperVisible, setIsSwiperVisible] = useState(false);
    const mediumMatches = useMediaQuery(theme.breakpoints.up("md"));
    const [total, setTotal] = useState(null);
    const [mapFullScreenBox, setMapFullScreenBox] = useState(false);

    const {
        listingType,
        propertyType,
        setMapView,
        mapView,
        mapFullScreen,
        setMapFullScreen,
    } = useProperty();

    const [map, setMap] = React.useState(null)
    const [overlayPane, setOverlayPane] = useState(OVERLAY_MOUSE_TARGET)

    const onLoad = React.useCallback(function callback(map) {
        mapRef.current = map;
        const bounds = new window.google.maps.LatLngBounds(center);
        //map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    const fetchNearByPlaces = (property) => {
        clearDirectionRenderer();
        setNearbyPlaces([]);
        var request = {
            location: {
                lat: property.latitude,
                lng: property.longitude
            },
            radius: "500", //in meters
            type: [nearbyFilterType],
        };
        var service = new google.maps.places.PlacesService(map);
        return service.nearbySearch(request, function (results, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                setNearbyPlaces(results);
            }
            return false;
        });
    };

    const calculateRoute = (end, property) => {
        var rObj = [...renderObjects];
        clearDirectionRenderer();
        let polylineOptionsActual = new google.maps.Polyline({
            strokeColor: '#00c4b3',
            strokeOpacity: 1.0,
            strokeWeight: 4
        });

        let directionsService = new google.maps.DirectionsService();
        let directionsRenderer = new google.maps.DirectionsRenderer({
            suppressMarkers: true,
            polylineOptions: polylineOptionsActual
        });

        var request = {
            origin: `${property.lat},${property.lng}`,
            destination: `${end.lat()},${end.lng()}`,
            travelMode: "DRIVING",
        };

        directionsRenderer.setMap(mapRef.current);
        rObj.push(directionsRenderer);
        setSenderObjects(rObj);

        directionsService.route(request, function (result, status) {
            if (status == "OK") {
                directionsRenderer.setDirections(result);
                var leg = result.routes[0].legs[0];
                setSelectedFacility({
                    place_id: property.place_id,
                    ...leg
                });
            }
        });
    };

    const clearDirectionRenderer = () => {
        for (var i in renderObjects) {
            renderObjects[i].setMap(null);
        }
    }

    useEffect(() => {
        if (nearbyFilterType && selectedProperty) {
            fetchNearByPlaces(selectedProperty);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nearbyFilterType]);

    useEffect(() => {
        clearDirectionRenderer();
    }, []);

    useEffect(() => {
        fetchItems();
    }, [listingType]);


    function handleZoomChanged() {
        if (mapRef.current) {
            const currentZoom = mapRef.current.getZoom();
            const bounds = mapRef.current.getBounds();

            if (zoom != currentZoom) {
                setZoom(currentZoom);
                setBounds(bounds);
                fetchItems();
            }
        }
    };

    const onDragEnd = () => {
        fetchItems();
    }

    const fetchItems = async () => {
        try {
            const { data } = await axios.post("/search/v1/listings/mapfilters", {
                northWestCoordinate: {
                    Latitude: bound?.getNorthEast()?.lat() ? bound?.getNorthEast()?.lat() : 0,
                    Longitude: bound?.getSouthWest()?.lng() ? bound?.getSouthWest()?.lng() : 0,
                },
                southEastCoordinate: {
                    Latitude: bound?.getSouthWest()?.lat() ? bound?.getSouthWest()?.lat() : 0,
                    Longitude: bound?.getNorthEast()?.lng() ? bound?.getNorthEast()?.lng() : 0,
                },
                page: 0,
                pageSize: perPage,
                listingTypeFilter: listingType == "buy" ? "sale" : listingType,
                propertyType: propertyType == "null" ? null : propertyType,
                orderBy: "price desc",
                defaultCity: Cookies.get("city_id"),
            },
                {
                    headers: {
                        "Accept-Language": router?.locale == "en" ? "en-US" : "ar-SA en-US",
                    },
                });
            // console.log('ddd' ,data?.data);
            setTotal(data?.data?.totalResults)

            if (data?.data?.totalResults > 0) {
                var newArr = [];
                data?.data?.results.map((location) => {
                    newArr.push(location);
                });

                let newDataArr = [];
                const countsByCs = {};
                newArr.forEach(({ latitude }) => {
                    countsByCs[latitude] = (countsByCs[latitude] || 0) + 1;
                    if (countsByCs[latitude] > 1) {
                        newDataArr.push(latitude)
                    }
                });

                setDupMarkers(newDataArr);
                setMarkers(newArr);
            }
        } finally {

        }
    };

    return (
        <WholeMapComponentWrapper
            height={'100vh'}
            mapFullScreen={mapFullScreen}
            className={mapFullScreen ? "map_fullscreen_toggle" : ""}
            >
            {map != null && (
                <PropertyInfoDrawerMap
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    setMapDrawer={setMapDrawer}
                    listingType={listingType}
                    propertyType={propertyType}
                    highlight={(property) => {
                        setSelectedProperty(property);
                        fetchNearByPlaces(property);
                        setCenter({
                            lat: property.latitude,
                            lng: property.longitude,
                        });
                        setZoom(17)
                    }}
                />
            )}
            
            {mapFullScreen ? _mapFullScreenBox() : _mapBox()}

            {/* Nearby Location Icon */}
            {selectedProperty != null && (
                <>
                    <NearByComponent 
                        setNearbyFilterType={setNearbyFilterType}
                        setDetailViewSt={setSelectedProperty}
                        nearbyFilterType={nearbyFilterType}
                        setNearbyPlaces={setNearbyPlaces}
                        setZoom={setZoom}
                        clearDirectionRenderer={clearDirectionRenderer}
                        setSelectedFacility={setSelectedFacility}
                        nearByFilterTypes={nearByFilterTypes}
                        />
                </>
            )}

            {/* Property Info */}
            {selectedProperty != null && (
                <InfoWrapper>
                    <InfoWindowComponent 
                        property={selectedProperty}
                        setDetailViewSt={setSelectedProperty}
                        setNearbyPlaces={setNearbyPlaces}
                        clearDirectionRenderer={clearDirectionRenderer}
                        setSelectedFacility={setSelectedFacility}
                        />
                </InfoWrapper>
            )}
        </WholeMapComponentWrapper>
    )

    function _mapBox() {
        return (
            <LoadScript
                id="script-loader"
                googleMapsApiKey={process.env.NEXT_PUBLIC_GMAP_KEY}
                language="en"
                region="sa"
                libraries={["places", "geometry"]}
            >
                <GoogleMap
                    mapContainerStyle={mapFullScreen ? containerFullStyle : containerStyle}
                    center={center == null ? useCenter : center}
                    zoom={zoom}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    clickableIcons={false}
                    options={{
                        mapTypeControl: false,
                        styles: mapStyles,
                        maxZoom: 31,
                        gestureHandling: 'greedy',
                        fullscreenControl: false,
                        mapTypeControl: false,
                        zoomControl: false
                    }}
                    onBoundsChanged={handleZoomChanged}
                    onDragEnd={onDragEnd}
                    >
                
                    {/* total */}
                    {(selectedProperty === null && zoom > 10) && (
                        total > 50 ?
                            <ResultCountOverlay>
                                <p>{t('total-more-than-50', { total })}</p>
                            </ResultCountOverlay>
                            :
                            <ResultCountMinOverlay>
                                <p>{t('total-less-than-50', { total })}</p>
                            </ResultCountMinOverlay> )
                    }

                    {/* User */}
                    <OverlayView
                        position={useCenter}
                        getPixelPositionOffset={centerOverlayView}
                        mapPaneName={overlayPane}
                    >
                        <img src="/images/svg/icon-material-location-on.svg" class="Icon-material-location-on" />
                    </OverlayView>

                    {/* Properties */}
                    {selectedProperty == null && (
                        total <= 50 ? (<MarkerClusterer
                            averageCenter
                            enableRetinaIcons
                            styles={clusterMarker()}
                        >

                            {clusterer => {
                                return markers.length > 0 && markers.map((property, k) => {
                                    let propertyMarker = 'data:image/svg+xml;base64,' + window.btoa(listingMarkers(property?.price));
                                    let projectMarker = 'data:image/svg+xml;base64,' + window.btoa(projectMarkers(property?.price));

                                    let checkDupMarker = dupMarkers.includes(property.latitude);

                                    return (

                                        <Marker
                                            key={k}
                                            position={{
                                                lat: checkDupMarker ? property.latitude + (0.1 / k) : property.latitude,
                                                lng: property.longitude,
                                            }}
                                            clusterer={clusterer}
                                            label={property?.price}
                                            icon={{ url: property.documentType == 1 ? projectMarker : propertyMarker }}
                                            onClick={() => {
                                                event({
                                                    action: "listing_pin_clicked",
                                                    params: {}
                                                });

                                                setSelectedProperty(property);
                                                fetchNearByPlaces(property);
                                                setCenter({
                                                    lat: property.latitude,
                                                    lng: property.longitude,
                                                });
                                                setZoom(17)
                                                setMapDrawer(!mapDrawer);
                                                setDrawerOpen(!drawerOpen)
                                            }}
                                        >
                                        </Marker>
                                    )
                                })
                            }}
                        </MarkerClusterer>) : (
                            markers.map((property, k) => {
                                let checkDupMarker = dupMarkers.includes(property.latitude);
                                let propertyMarker = 'data:image/svg+xml;base64,' + window.btoa(listingMarkers(property?.price));
                                let projectMarker = 'data:image/svg+xml;base64,' + window.btoa(projectMarkers(property?.price));
                                return (
                                    <Marker
                                        key={k}
                                        position={{
                                            lat: checkDupMarker ? property.latitude + (0.1 / k) : property.latitude,
                                            lng: property.longitude,
                                        }}
                                        label={property?.price}
                                        icon={{ url: property.documentType === 1 ? projectMarker : propertyMarker }}
                                        onClick={() => {
                                            event({
                                                action: "listing_pin_clicked",
                                                params: {}
                                            });

                                            setSelectedProperty(property);
                                            fetchNearByPlaces(property);
                                            setCenter({
                                                lat: property.latitude,
                                                lng: property.longitude,
                                            });
                                            setZoom(17);
                                            setMapDrawer(!mapDrawer);
                                            setDrawerOpen(!drawerOpen);
                                        }}
                                    />
                                );
                            })
                        )
                    )}
                    {/* Nearby places */}
                    {nearbyPlaces.length != 0 && nearbyPlaces.map((place, k) => {
                        return (
                            <OverlayView
                                key={k}
                                position={place.geometry.location}
                                getPixelPositionOffset={centerOverlayView}
                                mapPaneName={overlayPane}
                            >
                                <FacilityMarkers
                                    place={place}
                                    calculateRoute={calculateRoute}
                                    nearbyFilterType={nearbyFilterType}
                                    selectedProperty={selectedProperty}
                                    selectedFacility={selectedFacility}
                                />
                            </OverlayView>
                        )
                    })}

                    {/* Selected Property Marker */}
                    {selectedProperty != null && (
                        <SelectedMarker property={selectedProperty} />
                    )}

                    {_iconFloat()}
                </GoogleMap>
            </LoadScript>
        )
    }

    function _mapFullScreenBox() {
        return (
            <div style={{width: '100%', backgroundColor: '#fff', zIndex: 2000}}>
                {_mapBox()}
            </div>
        )
    }

    function _iconFloat() {
        if(mapFullScreen) {
            return (
                <div style={router.locale == "ar" ? iconStyleAr() : iconStyleEn()}>
                    <Paper elevation={1}>
                        <div style={{padding: 5, cursor: 'pointer'}} onClick={() => setMapFullScreen(false)}>
                            <MdCloseFullscreen size={25}/>
                        </div>
                    </Paper>
                </div>
            )
        }else {
            return (
                <div style={router.locale == "ar" ? iconStyleAr() : iconStyleEn()}>
                    <Paper elevation={1}>
                        <div style={{padding: 5, cursor: 'pointer'}} onClick={() => setMapFullScreen(true)}>
                            <MdFullscreen size={25}/>
                        </div>
                    </Paper>
                </div>
            )
        }
    }
};

const iconStyleEn = (locale) => {
    return {
        position: 'absolute', 
        right: 10,
        top: 10, 
        zIndex: 1000
    }
}

const iconStyleAr = (locale) => {
    return {
        position: 'absolute',
        left: 10,
        top: 10, 
        zIndex: 1000
    }
}

export default PropertyMapComponent;
